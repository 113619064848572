export const DAG_NEEDED = 250000;
export const DAG_NEEDED_PER_NFT = 5000;
export const FANTOM_CHAIN_PARAMS = {
    // chainId: '0xfa2',
    //4002 testnet, 1337 local
    // chainName: 'Fantom Opera Testnet',
    // rpcUrls: ['https://rpc.testnet.fantom.network/'],
    chainId: '0xfa',
    chainIdNumber: 250,
    chainName: 'Fantom Opera',
    rpcUrls: ['https://rpc.ftm.tools'],
    // chainId: '0x539',
    // chainName: 'Fantom Localhost',
    // rpcUrls: ['http://127.0.0.1:8545/'],
}
//todo set local/production

export const FTM_DECIMALS = 18

// CURRENCIES
export const CURRENCIES = new Set([
    'USD',
    'EUR',
    'IDR',
    'GBP',
    'JPY',
])

export const CURRENCY_SYMBOLS = {
    "USD": "$",
    "GBP": "£",
    "JPY": "¥",
    "IDR": 'Rp',
    "EUR": "€"
}

export const OTHER_TOKEN_SYMBOLS = [
    "AVAX",
    "BNB",
    "ETH",
    "USDC",
    "USDT"
]

export const OTHER_TOKENS = {
    "AVAX": "Avalanche C-Chain",
    "BNB": "BNB Chain (Binance Smart Chain)",
    "ETH": "Ethereum network",
    "USDC": "Ethereum network",
    "USDT": "Ethereum network",
}

export const DIVEST_TOKENS = [
    "AVAX", "BNB", "DAG", "ETH", "FTM", "USDC", "USDT"
]

export const DIVEST_TOKEN_ID = {
    "AVAX": 1,
    "BNB": 2,
    "DAG": 3,
    "ETH": 4,
    "FTM": 5,
    "USDC": 6,
    "USDT": 7
}

export const NFT_STATUS = {
    "COMPOUND": 0,
    "CLAIM_REWARDS": 1,
    "DIVEST": 2,
    "BURNABLE": 3,
    "BURNED": 4
}

export const USDT = "USDT"
export const BNB = "BNB"
export const DAG = "DAG"
export const FTM = "FTM"
export const USDC = "USDC"
export const AVAX = "AVAX"
export const ETH = "ETH"

export const ETH_WALLET_ADDRESS = '0x102349infalkdjf'
// todo 

export const APY = 0.04
export const INVESTORSHARE = 0.8