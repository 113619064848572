import 'bootstrap/dist/css/bootstrap.css'; // or include from a CDN
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import { lazy } from 'react';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import SuspenseLoading from './components/common/suspense-loading/suspense-loading';
import Layout from './components/layout/layout';
import toast, { Toaster } from 'react-hot-toast';

const InvestPool = lazy(() => import('./pages/pool/invest-pool/invest-pool'))
const NodePool = lazy(() => import('./pages/pool/node-pool/node-pool'))

export default function App() {

  return (
    <>
      <Toaster />
      <Layout>
        <Suspense fallback={<SuspenseLoading />}>
          <Routes>
            <Route path="/" element={<InvestPool />} />
            <Route path="/node-pool" element={<NodePool />} />
          </Routes>
        </Suspense>
      </Layout>
    </>
  );
}
