import { Button } from "react-bootstrap";
import './round-button.scss'

export default function RoundButton({
    onClick,
    variant,
    className,
    children,
    disabled,
    rest
}) {
    return (
        <Button disabled={disabled} onClick={onClick} variant={variant} className={`${className} default-button default-button-primary`} {...rest}>
            {children}
        </Button>
    )
}