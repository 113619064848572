import Lottie from "lottie-web";
import { useEffect } from "react";
import { createRef } from "react";
import Spinner from '../../../assets/animations/spinner.json'
import clsx from 'clsx'
import styles from './loading-spinner.scss'

export default function LoadingSpinner({ text, size = 43, style }) {
    const logoRef = createRef()

    useEffect(() => {

        const logoAnim = Lottie.loadAnimation({
            container: logoRef.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: Spinner,
        });

        return () => logoAnim.destroy()
    }, [logoRef]);

    return (
        <div className={clsx(styles.wrapper)} role="status">
            <div ref={logoRef} style={{ height: `${size}px`, ...style }} />
            {text && <p>{text}</p>}
        </div>
    );
}