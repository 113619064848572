import create from 'zustand'

export const useStore = create(set => ({
    provider: undefined,
    setProvider: provider => set(_state => provider),
    ftmApproved: false,
    setFtmApproved: ftmApproved => set(_state => ftmApproved),
    selectedCurrency: 'USD',
    setSelectedCurrency: currency => set(_state => currency),
    rangeValue: 5000,
    setRangeValue: rangeValue => set(_state => rangeValue),
    inputValue: 5000,
    setInputValue: inputValue => set(_state => inputValue),
    fiatDepositPriceFtm: 0,
    setFiatDepositPriceFtm: fiatDepositPriceFtm => set(_state => fiatDepositPriceFtm),
    ftmDepositValue: 0,
    setFtmDepositValue: ftmDepositValue => set(_state => ftmDepositValue),
    fiatDepositPriceFtmNumber: 0,
    setFiatDepositPriceFtmNumber: fiatDepositPriceFtmNumber => set(_state => fiatDepositPriceFtmNumber),

    selectedOtherDepositToken: 'ETH',
    setSelectedOtherDepositToken: selectedOtherDepositToken => set(_state => selectedOtherDepositToken),
    otherDepositRangeValue: 5000,
    setOtherDepositRangeValue: otherDepositRangeValue => set(_state => otherDepositRangeValue),
    otherDepositInputValue: 5000,
    setOtherDepositInputValue: otherDepositInputValue => set(_state => otherDepositInputValue),
    otherDepositFiatValue: 0,
    setOtherDepositFiatValue: otherDepositFiatValue => set(_state => otherDepositFiatValue),
    otherDepositValue: 0,
    setOtherDepositValue: otherDepositValue => set(_state => otherDepositValue),

    dagNftBalance: 0,
    setDagNftBalance: dagNftBalance => set(_state => dagNftBalance),
    ftmBalance: 0,
    setFtmBalance: ftmBalance => set(_state => ftmBalance),

    divestToken: "DAG",
    setDivestToken: divestToken => set(_state => divestToken),

    currentDagPrice: 0,
    setCurrentDagPrice: currentDagPrice => set(_state => currentDagPrice),

    totalInvestment: 0,
    setTotalInvestment: totalInvestment => set(_state => totalInvestment),

    nftsReadyToDivest: [],
    setNftsReadyToDivest: nftsReadyToDivest => set(_state => nftsReadyToDivest),

    totalDagDeposit: 0,
    setTotalDagDeposit: totalDagDeposit => set(_state => totalDagDeposit),

    unclaimedRewardIds: [],
    setUnclaimedRewardIds: unclaimedRewardIds => set(_state => unclaimedRewardIds),
}))