export const numberFormat = (num, locale = 'en-US', customOptions = {}) => {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...customOptions,
  };

  return new Intl.NumberFormat(locale, options).format(num);
};

export const withDecimal = (numberString, decimals) => {
  const options = {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  }

  let newStr = String(numberString).replaceAll(',', '')
  return numberFormat(Number(newStr), 'en-US', options)
}

export const handleShortenAddress = address => {
  let result = '';
  for (let i = 0; i < address.length; i++) {
    if (i < 5) {
      result += address[i];
    }
    if (i === address.length - 9) {
      result += '...';
    }
    if (i > address.length - 8) {
      result += address[i];
    }
  }
  return result;
};